export default {
  '提交订单': '提交訂單',
  '原价': '原價',
  '订单价': '訂單價',
  '数量': '數量',
  '物流方式': '物流方式',
  '付款方式': '付款方式',
  '货到付款': '貨到付款',
  '姓名': '姓名',
  '手机': '手機',
  '选择市区': '選擇市區',
  '选择地区': '選擇地區',
  '选择门市': '選擇門市',
  '详细地址': '詳細地址',
  '留言': '留言',
  '免运费': '免運費',
  '15天鉴赏期': '15天鑒賞期',
  '限时抢购': '限時搶購',
  '距离结束': '距離結束',
  '超商取货': '超商取貨',
  '订单查询': '訂單查詢',
  '立即购买': '立即購買',
  '新竹/黑貓配送': '新竹/黑貓配送',
  '超商取货': '超商取貨',
  '已收藏': '已收藏',
  '立即抢购': '立即搶購',
  '最新评价': '最新評價',
  '请输入姓名': '請輸入姓名',
  '请选择市区': '請選擇市區',
  '请选择地区': '請選擇地區',
  '请选择门市': '請選擇門市',
  '请输入详细地址': '請輸入詳細地址',
  '确认订单': '確認訂單',
  '订单时间': '訂單時間',
  '订单号': '訂單號',
  '商品资讯': '商品資訊',
  '商品合计': '商品合計',
  '运费': '運費',
  '手续费': '手續費',
  '应付': '應付',
  '优惠券': '優惠券',
  '优惠': '優惠',
  '下单成功': '下單成功',
  '订单详情': '訂單詳情',
  '送货地址': '送貨地址',
  '暂无数据': '暫無數據',
  '下单时间': '下單時間',
  '搜索': '檢索',
  '所有商品': '所有商品',
  '请输入您下单时成功显示的订单号': '請輸入您下單時成功顯示的訂單號',
  '输入关键字': '輸入關鍵字',
  '请输入订单号': '請輸入訂單號',
  '搜索关键字商品名称、ID、编号': '檢索關鍵字商品名稱、ID、編號',
  '政策详情': '政策詳情',
  '联系我们': '聯繫我們',
  '订单金额': '訂單金額',
  '请输入手机': '請輸入有效的號碼，09xxxxxxxx',
}