import VueI18n from 'vue-i18n'
import Vue from 'vue'


  
import zh_TW from './lang/zh_TW'
// 以下为语言包单独设置的场景，单独设置时语言包需单独引入

Vue.use(VueI18n)
const messages = {
  zh_TW
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale : 'zh_TW',
  messages,
})

export default i18n
