<template>
    <div class="relative">
        <div class="flex flex-wrap min-h-[100px]">
            <div class="w-1/2 sm:w-1/3 md:w-1/4" v-for="(k,i) in list">
                <div class="p-2 cursor-pointer" @click="jump(k)">
                    <div class="h-[100px]">
                        <img v-lazy="k.logoUrl" class="w-full h-full object-contain">
                    </div>
                    <div class="line-clamp-2">{{ k.name }}</div>
                    <div class="text-center font-extrabold" style="color:darkorange;">
                        <span>{{ $store.getters.symbol }}</span> <span>{{ k.discountPrice }}</span>
                    </div>
                    <div class="text-center line-through text-gray-400" v-if="Number(k.originalPrice)">
                        <span>{{ $store.getters.symbol }}</span> <span>{{ k.originalPrice }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50 text-center" v-if="loading">
            <van-loading/>
        </div>
        <van-empty v-if="!list.length && !loading" :description="$t('暂无数据')"/>
        <van-pagination v-if="list.length" v-model="pageNo" :total-items="total" :show-page-size="5" :items-per-page="pageSize" @change="onChange">
            <template #prev-text>
                <van-icon name="arrow-left" />
            </template>
            <template #next-text>
                <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
        </van-pagination>
    </div>
</template>

<script>
import { commodityInfoQueryPageList } from '@/api';
export default {
    name: 'CList',
    data() {
        return {
            list: [],
            loading: false,
            pageNo: 1,
            pageSize: 10,
            queryParam: { },
            total: 0,
        }
    },
    props: {
        id: String,
        keyword: String,
    },
    watch: {
        id: {
            immediate: true,
            handler(v) {
                if(!v) return;
                this.queryParam = {navigationId: v}
                this.refresh()
            }
        },
        keyword: {
            immediate: true,
            handler(v) {
                if(!v) return;
                this.queryParam = {searchKey: v}
                this.refresh()
            }
        },
    },
    methods: {
        onChange(v) {
            this.pageNo = v
            this.getList()
        },
        refresh() {
            this.getList(true)
        },
        search(params) {
            this.queryParam = params || {}
            this.getList(true)
        },
        getList(refresh) {
            if(refresh) {
                this.pageNo = 1
            }
            this.loading = true;
            commodityInfoQueryPageList({
                websiteId: this.$store.getters.websiteInfo.id,
                pageNo: this.pageNo, 
                pageSize: this.pageSize, 
                ...this.queryParam
            }).then(res => {
                this.total = res.data.total
                this.list = res.data.records
            }).finally(() => {
                this.loading = false
            })
        },
        jump(k) {
            this.$router.push('/' + k.id)
        }
    }
}
</script>