import axios from 'axios'
import { baseURL } from './config'
import {signature} from './sign'
import md5 from 'js-md5'
import store from '@/store'
import { Toast } from 'vant'

const instance = axios.create({
    baseURL,
    timeout: 120000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
})

instance.interceptors.request.use(config => {
    let s = []
    config.data.timestamp = Date.now()
    for(let k in config.data) {
        s.push(`${k}=${config.data[k]}`)
    }
    // let param = s.join('&')
    // let data = md5(param).toUpperCase()
    // data = signature(data, store.state.privateKey)
    // config.data = `${param}&signature=${data}`
    config.data = s.join('&')
    config.headers = {...config.headers, ...store.getters.clientInfo}
    return config
}, err => {
    return Promise.reject(err)
})

window.addEventListener('unhandledrejection', e => {
    e.preventDefault()
})

instance.interceptors.response.use(response => {
    if(response.data.code === 0) return response.data
    Toast.fail(response.data.msg)
    return Promise.reject(response.data.msg)
}, err => {
    return Promise.reject(err)
})

export default instance

export function requestByFetch(params) {
    fetch(baseURL + params.url, {
        method: params.method,
        headers: {
             "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
             ...store.getters.clientInfo
        },
        body: toFormdata(params.data),
        keepalive: true,
    })
}

function toFormdata(obj) {
    if(obj && typeof obj !== 'object') return ''
    const s = []
    for(let key in obj) {
        s.push(`${key}=${obj[key]}`)
    }
    return s.join('&')
}