import { ClientJS } from "clientjs"
import store from "@/store"
import md5 from "js-md5"
import commitType from "@/store/commitType"
const client = new ClientJS()

function getNetWork() {
    return navigator.connection ? navigator.connection.effectiveType : ''
}

function getDevice() {
    if(client.isMobileAndroid()) return 1
    if(client.isMobileIOS()) return 2
    if(client.isWindows() || client.isMac() || client.isUbuntu() || client.isLinux()) return 0
    return 3
}

function getIp(cb) {
    if(!RTCPeerConnection) return;
    const pc = new RTCPeerConnection({
        iceServers: [
            {"urls": "stun:stun.l.google.com:19302"}
        ]
    })
    pc.createDataChannel('')
    pc.onicecandidate = function(e) {
        if(e.candidate && e.candidate.relatedAddress) {
            cb(e.candidate.address)
        }
    }
    pc.createOffer().then(offer => {
        pc.setLocalDescription(offer)
    })
}

export function getSystemInfo() {
    const navigator = window.navigator
    getIp((ip) => {
        store.commit(commitType.SET_CLIENTIP, ip)
    })
    store.commit(commitType.SET_CLIENTINFO, {
        source: 2,
        appKey: '',
        channel: '',
        clientId: md5(client.getFingerprint().toString()),
        appVersion: navigator.appVersion,
        language: navigator.language,
        clientModel: '',
        clientOs: client.getOS(),
        clientOsVersion: client.getOSVersion(),
        networkType: getNetWork(),
        longitude: '',
        latitude: '',
        device: getDevice(),
        browser: client.getBrowser() + client.getBrowserVersion(),
    })
}