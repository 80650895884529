import router from "./router";
import NProgress from 'nprogress' 
import 'nprogress/nprogress.css'

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach((to, from) => {
 NProgress.done()
})