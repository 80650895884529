const SET_PUBLICKEY = 'setPublickKey'
const SET_PRIVATEKEY = 'setPrivateKey'
const SET_WEBSITEINFO = 'setWebsiteInfo'
const SET_NAVINFO = 'setNavInfo'
const SET_POLICY = 'setPolicy'
const SET_CLIENTINFO = 'setClientInfo'
const SET_CLIENTIP = 'setClientIp'

export default {
    SET_PRIVATEKEY,
    SET_PUBLICKEY,
    SET_WEBSITEINFO,
    SET_NAVINFO,
    SET_POLICY,
    SET_CLIENTINFO,
    SET_CLIENTIP,
}