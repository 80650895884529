<template>
    <div class="py-10 bg-black text-center">
        <img src="../../assets/payment.png" class="inline-block h-6 mt-5 sm:h-10 ">

        <div class="flex flex-col m-auto max-w-[750px] justify-center mt-8 px-4 sm:flex-row sm:justify-between">
            <div v-if="policyList.length">
                <div v-for="(k,i) in policyList" :key="i">
                    <div class="cursor-pointer text-white text-opacity-80" @click="jump(k)">{{ k.name }}</div>
                </div>
            </div>
            <div v-if="$store.getters.websiteInfo.contactUs" class="text-white text-opacity-80 text-center sm:text-left">
                <div>{{ $t('联系我们') }}</div>
                <div class="text-sm">{{ $store.getters.websiteInfo.contactUs }}</div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
export default {
    name: 'CFooter',
    methods: {
        jump(k) {
            this.$router.push('/policy/' + k.id)
        }
    },
    computed: {
        policyList() {
            if(this.$store.getters.policy.generalStatus === 0) {
                return this.$route.path === '/product/search' ? this.$store.getters.policy.item2VOList : this.$store.getters.policy.item1VOList
            }
            if(this.$store.getters.policy.generalStatus === 1) {
                return this.$store.getters.policy.item0VOList
            }
            return []
        }
    }
}
</script>