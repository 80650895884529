export function copy(txt) {
    return new Promise((resolve, reject) => {
        var dom = document.createElement('fake');
        var selection = window.getSelection();
        var range = document.createRange();
        var err = 0;
        dom.innerText = txt;
        document.body.appendChild(dom);
        selection.removeAllRanges();
        range.selectNode(dom);
        selection.addRange(range);
        try {
            document.execCommand('copy');
        } catch(e) {
            err = 1;
        }
        dom.remove();
        err ? reject() : resolve();
    });
}

export function arrayToTree(ar) {
    const map = Object.create(null)
    const tree = []
    for(let i = 0; i < ar.length; i++) {
        map[ar[i].id] = ar[i]
    }
    for(let i = 0; i < ar.length; i++) {
        const pid = ar[i].parentId;
        if(map[pid]) {
            if(!map[pid].children) {
                map[pid].children = []
            }
            map[pid].children.push(ar[i])
        } else {
            tree.push(ar[i])
        }
    }
    return tree;
}

export function getNameById(ar, id) {
    for(let i = 0; i < ar.length; i++) {
        if(ar[i].id === id) return ar[i].name;
        if(ar[i].children) {
            const name = getNameById(ar[i].children, id)
            if(name) return name;
        }
    }
    return ''
}

export function isEmpty(v) {
    return v === '' || v === undefined || v === null
}

export function randomNum(min, max) {
    return (Math.random() * (max - min) + min) | 0
}