
import Vue from 'vue'
import App from './App'
import store from './store'
import i18n from './locales'
import router from './router'
import './permission'
import {Icon,Empty,Loading,NavBar,Toast, Collapse, CollapseItem, Pagination} from 'vant'
import VueLazyload from 'vue-lazyload'
import CUI from './package'
import '@vant/touch-emulator'
import lazy from './assets/lazy.png'
import Template from './template'
import './assets/tailwindcss.css'
import BigNumber from 'bignumber.js'
import { getSecretKeyId } from './api'
import { getKey } from './utils/sign'
import { getSystemInfo } from './utils/systemInfo'
import '@/assets/iconfont/iconfont.css'

getSystemInfo()
Toast.allowMultiple()

Toast.setDefaultOptions('loading', { forbidClick: true });
Vue.use(VueLazyload, {
  loading: lazy
})
Vue.use(Icon)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(CUI)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Template)
Vue.config.productionTip = false
Vue.prototype.$BN = BigNumber

// getSecretKeyId({
//   publicKey: getKey(store.state.publicKey)
// }).then(res => {
//   console.log(res)
// })

new Vue({
  el: '#app',
  router,
  i18n,
  store,
  render: h => h(App)
})
