import request from '@/utils/request'
import { requestByFetch } from '@/utils/request'

const urls = {
    websiteInfoGetByDomain: '/api/websiteInfo/getByDomain',
    getSecretKeyId: '/api/kaptcha/getSecretKeyId',
    websiteNavigationGet: '/api/websiteNavigation/get',
    websiteNavigationQueryPageList: '/api/websiteNavigation/queryPageList',
    commodityInfoQueryPageList: '/api/commodityInfo/queryPageList',
    commodityInfoGet: '/api/commodityInfo/get',
    websitePolicyGet: '/api/websitePolicy/get',
    templateInfoGet: '/api/templateInfo/get',
    templateRollBuyGet: '/api/templateRollBuy/get',
    sysAreaQueryTreeList: '/api/sysArea/queryTreeList',
    sysCountryLanguageLogisticsStoreQueryPageList: '/api/sysCountryLanguageLogisticsStore/queryPageList',
    sysAccessLogAdd: '/api/sysAccessLog/add',
    sysAccessLogSave: '/api/sysAccessLog/save',
    orderInfoAdd: '/api/orderInfo/add',
    orderInfoGet: '/api/orderInfo/getByNo',
    orderInfoQueryPageList: '/api/orderInfo/queryPageList',

}

export function orderInfoAdd(params) {
    return request({
        url: urls.orderInfoAdd,
        method: 'POST',
        data: params
    })
}

export function orderInfoGet(params) {
    return request({
        url: urls.orderInfoGet,
        method: 'POST',
        data: params
    })
}

export function orderInfoQueryPageList(params) {
    return request({
        url: urls.orderInfoQueryPageList,
        method: 'POST',
        data: params
    })
}

export function sysAccessLogAdd(params) {
    return request({
        url: urls.sysAccessLogAdd,
        method: 'POST',
        data: params
    })
}

export function sysAccessLogSave(params) {
    return requestByFetch({
        url: urls.sysAccessLogSave,
        method: 'POST',
        data: params
    })
}

export function sysCountryLanguageLogisticsStoreQueryPageList(params) {
    return request({
        url: urls.sysCountryLanguageLogisticsStoreQueryPageList,
        method: 'POST',
        data: params
    })
}

export function sysAreaQueryTreeList(params) {
    return request({
        url: urls.sysAreaQueryTreeList,
        method: 'POST',
        data: params
    })
}

export function templateRollBuyGet(params) {
    return request({
        url: urls.templateRollBuyGet,
        method: 'POST',
        data: params
    })
}

export function templateInfoGet(params) {
    return request({
        url: urls.templateInfoGet,
        method: 'POST',
        data: params
    })
}

export function commodityInfoGet(params) {
    return request({
        url: urls.commodityInfoGet,
        method: 'POST',
        data: params
    })
}

export function websitePolicyGet(params) {
    return request({
        url: urls.websitePolicyGet,
        method: 'POST',
        data: params
    })
}

export function commodityInfoQueryPageList(params) {
    return request({
        url: urls.commodityInfoQueryPageList,
        method: 'POST',
        data: params
    })
}

export function websiteNavigationQueryPageList(params) {
    return request({
        url: urls.websiteNavigationQueryPageList,
        method: 'POST',
        data: params
    })
}

export function websiteNavigationGet(params) {
    return request({
        url: urls.websiteNavigationGet,
        method: 'POST',
        data: params
    })
}

export function getSecretKeyId(params) {
    return request({
        url: urls.getSecretKeyId,
        method: 'POST',
        data: params
    })
}

export function websiteInfoGetByDomain(params) {
    return request({
        url: urls.websiteInfoGetByDomain,
        method: 'POST',
        data: params
    })
}

