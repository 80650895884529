import Vue from 'vue'
import Vuex from 'vuex'
import commitType from './commitType'
import { Toast } from 'vant';
import { sysAccessLogAdd, sysAccessLogSave, websiteInfoGetByDomain, websiteNavigationQueryPageList, websitePolicyGet } from '@/api';
import { arrayToTree } from '@/utils/util';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sysAccessId: '',
    fns: [],
    websiteInfo: {},
    clientInfo: {},
    navInfo: [],
    policy: {},
    clientIp: '',
    publicKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApAgSFfUmN36msZntsH7p
zDWe6UbsQZGOHqA5+cPUl7isXJRkvtgN1EAw9ezMLGwJL0r9BzA/AsVM7qTRnfla
Mczc/hRW7oovODuTLGe+Hf2uwgFOkfowBchjvGv9FzNO0KFUiJFZH2w3Ms4a1JjL
RAm22Fqwl1NQdymRHXHJNdpeWhx+bY3OEecIkkm4Mtpl3BXG2vy5MC9fcxC9n/GR
7AMaVSzAJKc1uJt7xOfpbvkVPq7uponERyy/dach2LVPHPG8OLYTkdgWbqQMzpg+
28I7ZkUbx/GCr3BbezZUVlm1HOfhd4xmL6LpxlCBK2vgePbKAdmlm6qKT5XJ6ldN
5QIDAQAB
-----END PUBLIC KEY-----`,
    privateKey: `-----BEGIN PRIVATE KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCkCBIV9SY3fqax
me2wfunMNZ7pRuxBkY4eoDn5w9SXuKxclGS+2A3UQDD17MwsbAkvSv0HMD8CxUzu
pNGd+VoxzNz+FFbuii84O5MsZ74d/a7CAU6R+jAFyGO8a/0XM07QoVSIkVkfbDcy
zhrUmMtECbbYWrCXU1B3KZEdcck12l5aHH5tjc4R5wiSSbgy2mXcFcba/LkwL19z
EL2f8ZHsAxpVLMAkpzW4m3vE5+lu+RU+ru6micRHLL91pyHYtU8c8bw4thOR2BZu
pAzOmD7bwjtmRRvH8YKvcFt7NlRWWbUc5+F3jGYvounGUIEra+B49soB2aWbqopP
lcnqV03lAgMBAAECggEBAIk5wz+hXn5wsy3DU8ki858GgNIxpjbYeWf/rWDZRsJD
Gdc9weaywyb9KLikiM7k9u4csv/Pifdcbhb7xe1+DMm61GqvQ2a+LfDCTkaI59EZ
SJk/FKclAgXkdiPMx+gf4WDChwDVcBTMqIUVTfbreKmL4u6vOCrw5VE7cg6LAcTN
eKQf5nbJH/WcCFr70oZaSriC8+4wNiGLIHfSYmqR3VJ2/yENonohsAqWuG9gJevA
d1xizUTdNDn7hXHew+dCYj/fgVZYpu180IGFSaTDoTTizizmQJ3LQZanuB5zS7As
HXNoJewJl0b69uRkwlwwkIG1GMa7es2zHJ2RDtfQueECgYEA5baWrpiSCcPipe6H
+GANJqG1iurXIpi+ca7Sb6OOioJusNAmCsepPH3CAQZlr6OFqbSNXH9eWW1uWoxO
EjOFATw9i1KMf2nKgrlN+2lMAoZOwdfC0Y8/QnhW8rpN32s1/wED/kUXr00tu48Q
z1Ctb7B5MRyvNMpyz70cYIMfqE0CgYEAts1Y31XVNaDV2OR/5F+VqgJ2oC5gsJ1F
lF6kPgDZbCngO6rj3mvAMbLC3cOeaGmuFEDuSlPocQW9CI/FuXva9SWqN2/K8jVJ
bpFVLOR+6TbhrYIH3884C8CM+UeNgQwHzhxsoj4W2Ouu2c71XctlgJ/l7dHp0OZe
pTdy+I/8h/kCgYAq/zlHJwG22DaVToee4wOzJnOftANe025aQxnakae9n1puuBdW
zzaLPxLQNE1WSepb8JLBLIoRfR05z6dRWtlbeNYgPOxVcQzZ2SuSkowKsO8O837g
0MpLe9DykBLBbgTmXV9M+cvMIUsdH1NLKtO74zFcEv1siTRiVAh6vDBxxQKBgFrM
yy08LEiUGETA00nztJqa2KOeBccUh6A4Fmr57wWME2Zv7vnnGbNV8PdImKGShLZo
vjRLU3T4fboH+BW3EL6eM9uPewYuM8hpBh7jbKqY6SZPJWwlONiVjzCvOvlzqVjW
w5sfLigXgWWNKFGrm0J1qjckAnkSRMbaMRCILM65AoGBAL0McO4+KNFGFuABjJcQ
8m+JqDcXHZWGZV6Ec59clPI7sFMjKC/HSdaE2mxWuj1SsmNpvBaze4f2YkviF6Fq
M4tpQ2scIEInQRPrjvBHu9Xh3t3vs0gJ2ny+RDo6R/W410HNC86PcG4rXwi0Sb8w
umL7S6+AxH85IosMNRAGCvV3
-----END PRIVATE KEY-----`
  },
  getters: {
    websiteInfo: state => state.websiteInfo,
    publickKey: state => state.publicKey,
    privateKey: state => state.privateKey,
    navInfo: state => state.navInfo,
    policy: state => state.policy,
    clientInfo: state => state.clientInfo,
    clientIp: state => state.clientIp,
    symbol: state => state.websiteInfo.symbol || '',
  },
  mutations: {
    [commitType.SET_PUBLICKEY](state, v) {
      state.publicKey = v
    },
    [commitType.SET_PRIVATEKEY](state, v) {
      state.privateKey = v
    },
    [commitType.SET_WEBSITEINFO](state, v) {
      state.websiteInfo = v
      state.fns.forEach(fn => fn())
      state.fns = []
    },
    [commitType.SET_NAVINFO](state, v) {
      state.navInfo = v
    },
    [commitType.SET_CLIENTINFO](state, v) {
      state.clientInfo = v
    },
    [commitType.SET_CLIENTIP](state, v) {
      state.clientIp = v
    },
    [commitType.SET_POLICY](state, v) {
      state.policy = v
    }
  },
  actions: {
    pendingCallback({state}, v) {
      state.fns.push(v)
    },
    initWebsite({commit, state}) {
      Toast.loading({duration: 0})
      return websiteInfoGetByDomain({domain: getDomain()}).then(res => {
        commit(commitType.SET_WEBSITEINFO, res.data)
        websiteNavigationQueryPageList({websiteId: res.data.id}).then(res => {
          commit(commitType.SET_NAVINFO, arrayToTree(res.data.records))
          Toast.clear()
        })
        if(state.websiteInfo.iconUrl) {
          let link = document.querySelector('link[rel*=icon]')
          link.href = state.websiteInfo.iconUrl
        }
        if(state.websiteInfo.policyId !== '0') {
          websitePolicyGet({id: state.websiteInfo.policyId}).then(res => {
            commit(commitType.SET_POLICY, res.data)
          })
        }
      })
    },
    sysAccessLogAdd({state}, params) {
      params = params || {}
      const fn = () => {
          sysAccessLogAdd({
              ipAddress: state.clientIp,
              actionType: 1,
              websiteId: state.websiteInfo.id,
              ...params
          }).then(res => {
              if(!state.sysAccessId) state.sysAccessId = res.data;
          })
      }
      !state.websiteInfo.id ? state.fns.push(fn) : fn()
    },
    sysAccessLogSave({state}) {
      if(!state.sysAccessId) return
      sysAccessLogSave({id: state.sysAccessId});
      state.sysAccessId = ''
    }
  },
  modules: {
  }
})

function getDomain() {
  return document.location.hostname === 'localhost' ? 'abc.com' : document.location.hostname
}