<template>
  <div id="app">
    <Keep-alive :include="includes" :max="10">
      <router-view/>
    </Keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      includes: []
    }
  },
  created(){
    this.$store.dispatch('initWebsite').then(() => {
      this.$i18n.locale = this.$store.getters.websiteInfo.langCode
    })
    this.keepRoute()
    // window.oncontextmenu = function() {
    //   return false;
    // }
  
    window.onbeforeunload = () => {
      this.$store.dispatch('sysAccessLogSave')
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.setTitle()
        this.keepRoute()
      }
    },
    '$store.getters.websiteInfo'() {
      this.setTitle()
    }
  },
  methods: {
    setTitle() {
      const siteName = this.$store.getters.websiteInfo.name || ''
      document.title = this.$route.meta.title ? this.$i18n.t(this.$route.meta.title) + ' - ' + siteName : siteName
    },
    keepRoute() {
      if(this.$route.meta.keepAlive && !this.includes.includes(this.$route.name)) {
        this.includes.push(this.$route.name)
      }
    }
  }
}
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: 'SimHei' !important;
  /* margin-top: 60px; */
  margin: auto;
}
</style>
